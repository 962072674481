import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import ads, { loadFonts } from "@nswdoe/doe-ui-core-v3";
// disable the above line and enable the below line when running dev for HMR
// import ads, {loadFonts} from '@nswdoe/doe-ui-core-v3/raw'

import "highlight.js/styles/stackoverflow-light.css";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import LoadVueFile from "@/utils/LoadVueFile.vue";

// ADS 3 styling
import "vuetify/styles";
import "./styles/main.scss";

hljs.registerLanguage("javascript", javascript);

loadFonts();

const app = createApp(App);
app.use(vuetify);
app.use(ads);
app.use(createPinia());
app.use(router);
app.use(hljsVuePlugin);

hljs.configure({
  ignoreUnescapedHTML: true,
  languages: [
    "javascript",
    "css",
    "python",
    "html",
    "bash",
    "java",
    "sql",
    "json",
    "http",
    "go",
    "c++",
    "c#",
    "",
  ],
});
app.directive("highlight", function (el: any) {
  el.querySelectorAll("pre code").forEach((block: any) => {
    hljs.highlightElement(block);
  });
});

app.component("LoadVueFile", LoadVueFile);

app.mount("#app");
